import React from "react";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { TCredentials } from "../helpers/api/facility";

const HelpBlock = ({
  user,
}: {
  user: { email?: TCredentials; phone?: TCredentials; name?: string };
}) => {
  return (
    <div className="d-flex w-100 justify-content-center">
      <div className="help-block">
        <div className="content">
          {user.name && (
            <React.Fragment>
              <h1>Need help? Be in touch!</h1>
              <h2>{user.name}</h2>
              <span>Human Resourses Manager</span>
              <div className="d-flex w-100 justify-content-center">
                <div className="d-flex flex-row justify-content-center gap-2 credential-block">
                  {user.phone && (
                    <a
                      href={`tel:${user.phone.credential}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="credentials">
                        <FaPhoneVolume />
                        {user.phone.credential}
                      </div>
                    </a>
                  )}

                  {user.email && (
                    <a
                      href={`mailto:${user.email.credential}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="credentials">
                        <MdEmail />
                        {user.email.credential}
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpBlock;
