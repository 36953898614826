import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FacilityLandingPage from '../pages/FacilityLandingPage';

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<FacilityLandingPage />} />
        <Route path="/:company/:facility" element={<FacilityLandingPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
