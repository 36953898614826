import axios from "axios";
import { api } from "../config";

export const API = axios.create({
  baseURL: api.API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
