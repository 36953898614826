import logo from "../assets/images/logo-white.png";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="upper">
        <select>
          <option value="someOption">English</option>
        </select>
        <img src={logo} alt={'logo'} />
        <div className="social-media">
          <div className="icon">
            <PiInstagramLogoFill />
          </div>
          <div className="icon">
            <FaFacebookF />
          </div>
          <div className="icon">
            <FaLinkedinIn />
          </div>
        </div>
      </div>
      <div className="lower">
        <span>Copyright © 2023. Mastered.</span>
        <span>Created by spaceberry.studio</span>
      </div>
    </div>
  );
};

export default Footer;
